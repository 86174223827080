.footer{
  background: #001D4C;
  padding: 90px 0px 90px 0px;
}


.footer-widget{
	.widget-title{
		color: $light;
		text-transform: capitalize;
		font-size: 18px;
		margin-bottom: 25px;
	}

	p,ul li{
		color: rgba(255,255,255,.7);
	}
	.footer-links{
		li{
			margin-bottom: 6px;
		}
		a{
			color: rgba(255,255,255,.9);
			font-weight: 700;
		}
	}
}

.footer-socials{
	a{
		color: rgba(255,255,255,.8);
		margin-right: 10px;
		i{
			margin-right: 8px;
		}
	}
}

.copyright{
	p{
		color: rgba(255,255,255,.8);
	}
}

.footer-btm{
	background:#00173E;
	padding: 20px 0px 10px;
}

.subs-form{
	margin-top: 20px;
	input{
		background: $light;
		border-color:#eee;
		margin-bottom: 15px;
	}
}

.footer-logo{
	font-size: 60px;
	line-height: 1;
	color: $light;
	font-weight: 800;
}