.bg-grey{
  background: #f7f7f7;
}

.feature{
  margin-top: -150px;
}
.feature-item{
  padding: 50px 30px;
  border-radius: 5px;
  background: $light;
  text-align: center;
  border:1px solid #f7f7f7;
  -webkit-transition: all .45s ease;
     -moz-transition: all .45s ease;
      -ms-transition: all .45s ease;
       -o-transition: all .45s ease;
          transition: all .45s ease;
  i{
    width: 45px;
    height: 45px;
    display: inline-block;
    border-radius: 7px;
    text-align: center;
    padding-top: 15px;
    color: $light;
  }
  h4{
    margin-top: 25px;
    margin-bottom:10px;
  }
  a{
    text-transform: capitalize;
  }

  &:hover{
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.05);
  }
}

.c1{
  background: #4A46DD;
}
.c2{
  background: #FEA369;
}
.c3{
  background: #F48687;
}
.c4{
  background: #1BDBAC;
}


//  ABout 
//  

.about-item{
  h2{
    margin-bottom: 25px;
    margin-top: 15px;
  }
  p{
    margin-bottom: 30px;
  }
  
}



//  Services
//  
.service-heading{
  width: 70%;
  margin: 0 auto;
}
.about-item-block{
  display: flex;
  align-items: center;
  border:1px solid rgba(0,0,0,0.04);
  border-radius:5px;
  padding:10px;
  padding-left: 0px;
  margin-bottom: 20px;
  background: $light;
  box-shadow:0px 0px 20px rgba(0,0,0,0.04);


  h4{
    font-weight: 400;
    font-size: 18px;
    text-transform: capitalize;
  }

  .about-item-icon{
    margin-right: 20px;
    background: rgba(23, 33, 130,.2);
    padding: 15px;
    border-radius: 0px 10px 0px 0px;
    // border-left:2px solid $primary-color;
    img{
      width: 30px;
    }
  }
}

//  Counter Section

.heading{
  margin-bottom:70px;
  h2{
    margin-top: 10px;
  }

}


.counter{
  background: #eee;
  padding: 60px 0px;
  background: url("../images/bg/counterbg2.png") no-repeat;
  background-size: cover;
  position: relative;
}
.counter-item{
  text-align: center;
  span{
    font-size: 60px;
    line-height: 1;
    color: $primary-color;
  }
  p{
    color: $black;
    margin-top: 10px;
  }

  i{
    display: block;
    margin-bottom: 20px;
    font-size: 40px;
    color: $secondary-color;
  }
}

.text-color{
  color: $secondary-color;
}

//  Working Processs
//  

.process-item{
  padding: 20px;
  margin-bottom: 15px;
  i{
    font-size: 40px;
    width: 110px;
    height:110px;
    background: #eee;
    border:10px solid $light;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.6);
    display: inline-block;
    border-radius: 100%;
    padding-top: 25px;
    color: $primary-color;
    text-align: center;
  }
  h4{
    margin-top: 20px;
  }
}


//  Testimonial
//  

.testimonial{
  padding-bottom: 150px;
}
.testimonial-item{
   padding: 40px;
   border:1px solid #f7f7f7!important;
   background: $light;
   position: relative;
   margin: 5px 0px;
   .quote-icon{
      position: absolute;
      content:"";
      right:40px;
      bottom:0px;
      font-size: 100px;
      opacity: .08;
      color: $primary-color;
   }
   .rating{
    a{
      font-size: 22px;
      color: $secondary-color;
    }
   }

  p{
    font-size: 18px;
    margin: 15px 0px 35px;
  }

  .testimonial-footer {
    p{
      font-size: 14px;
      margin: 0px ;
    }
  }
}

.test-img{
  background: url("../images/bg/6.jpg");
  width: 100%;
  background-size: cover;
  height:450px;
  position: relative;

  .testimonial-slider {
    position: absolute;
    content: "";
    right: -71px;
    bottom: -65px;
  }
}

//  CTA


.cta{
  background: url("../images/banner/blog-2.jpg") 50% 50% no-repeat;
  position: relative;
  background-size: cover;

  &:before{
    position: absolute;
    content:"";
    left: 0px;
    top:0px;
    width: 100%;
    height: 100%;
    background: rgba(23, 33, 130,.95);
  }

  .cta-content {
    h2{
      color: $light;
    }
  }
}


//  Service pages
//  

.service-block{
  margin-bottom: 30px;
  padding:50px 40px;
  text-align: center;
  position: relative;
  background: #eee;
  overflow: hidden;
  border-radius: 5px;

  .number{
    font-weight: 800;
    font-size: 150px;
    opacity: .08;
    position: absolute;
    content: "";
    bottom: -90px;
    right: 0px;
  }
  img{
    width: 70px;
  }

  h4{
    margin-top: 20px;
  }
  p{
    margin-bottom: 0px;
  }
}


//  Feature 2

.about-us{
   h2{
    color: $primary-color;
  }
}

.feature-wrap{
  .feature-block{
     i{
      font-size: 40px;
      color: $secondary-color;
     }

     h4{
      margin-top: 20px;
     }
  }
}


//  Team Section


.team-item{
  text-align: center;
  border:1px solid #f7f7f7;
  background: $light;
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.03);
  padding: 25px;
  border-radius: 4px;
  -webkit-transition: all .45s ease;
     -moz-transition: all .45s ease;
      -ms-transition: all .45s ease;
       -o-transition: all .45s ease;
          transition: all .45s ease;
  .team-img{
    margin-bottom: 30px;
    img{
      border-radius: 100%;
      border:10px solid #f7f7f7;
    }
  }

  .team-info{
    h4{
      margin-bottom: 0px;
    }
    p{
      font-size: 14px;
    }
  }

  .team-info h4, .team-info p{
    -webkit-transition: all .45s ease;
     -moz-transition: all .45s ease;
      -ms-transition: all .45s ease;
       -o-transition: all .45s ease;
          transition: all .45s ease;
  }


  ul li a{
    color: #777;
    font-size: 14px;
    padding: 0px 3px;
    -webkit-transition: all .45s ease;
     -moz-transition: all .45s ease;
      -ms-transition: all .45s ease;
       -o-transition: all .45s ease;
          transition: all .45s ease;
  }

  &:hover{
    background: $primary-color;
  }

  &:hover h4,&:hover p{
    color: $light;
  }

  &:hover ul li a {
    color: $secondary-color;
  }
}


@include mobile-xs{
  .test-img .testimonial-slider {
      right: 0px;
  }
}

@include mobile{
  .test-img .testimonial-slider{
      right: 0px;
  }
}
@include tablet{
  .test-img .testimonial-slider {
      right: 0px;
  }
}
