
.navbar-toggle .icon-bar {
  background: $primary-color;
}

input[type="email"],input[type="password"],input[type="text"],input[type="tel"]{
  box-shadow:none;
  height:45px;
  outline: none;
  font-size:14px;
  &:focus {
    box-shadow: none;
    border:1px solid $primary-color;
  }
}


.form-control {
  box-shadow: none;
  border-radius: 0;
  &:focus {
    box-shadow:none;
    border:1px solid $primary-color;
  }
}

//

.py-7{
  padding: 7rem 0px;
}

// Button Style

.btn{
  font-size: 14px;
  letter-spacing: 0px;
  padding: 14px 25px;
  text-transform:capitalize;
  border-radius:4px;
  font-weight: 600;
  border:1px solid transparent;
  @include transition (all, 0.2s, ease);
}


.btn-main {
  background: $primary-color;
  color: $light;
  &:hover {
    background: darken( $primary-color, 5% );
    color: $light;
  }
}

.btn-main-2 {
  background: $secondary-color;
  color: $light;
  &:hover {
    background: darken( $secondary-color,5% );
    color: $light;
  }
}


.btn-white{
  background: $light;
  color: $black;
  border-color:$light;

  &:hover{
    background: $primary-color;
    color: $light;
    border-color:$primary-color;
  }
}

.btn-black{
  background:$black;
  color:$light;
  border-color:$black;

  &:hover{
    background: $primary-color;
    color: $light;
    border-color:$primary-color;
  }
}

.btn-solid-border {
  background:transparent;
  color:$black;
  border-color:rgba(0,0,0,0.08);
  &:hover {
    background:transparent;
    color:$primary-color;
  }
}

.btn-large {
  padding:20px 45px;
  &.btn-icon {
    i {
      font-size:16px;
      vertical-align:middle;
      margin-right:5px;
    }
  }
}
.btn-sm {
  @extend .btn-main ;
  padding:10px 22px;
  font-size:12px;
}

.btn-round {
  border-radius:4px;
}
.btn-round-full {
  border-radius:50px;
}


.btn.active:focus, .btn:active:focus, .btn:focus {
  outline: 0;
}




.bg-shadow {
  background-color: $light;
  box-shadow: 0 16px 24px rgba(0,0,0,.08);
  padding:20px;
}

.bg-gray {
  background:#f9f9f9;
}
.bg-primary {
  background:$primary-color;
}


.bg-dark {
  background:#202122;
}


.section {
  padding:100px 0;
}
.section-sm {
  padding:70px 0;
}

.section-title {
  margin-bottom: 70px;
  h2 {
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 600;
  }
  p {
    font-style: italic;
      color: #666;
      font-family:$secondary-font;
  }
}


.page-title{
  padding: 200px 0px 95px 0px;
  background: #eee;
  background-size: cover;
  .block{
    text-align: center;
    h1{
      font-size: 2.5rem;
    }
    ul li{
      color: $secondary-color;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 14px;
      a{
         font-weight: 600;
        color:$black;
      }
    }
  }
}



.page-wrapper {
  padding:70px 0;
}

.overly {
  position: relative;
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #1d192c;
    opacity: 0.8;
  }
}


#success , #error {
    display: none;
}


.font-sm{
  font-size: 14px;
}

.letter-spacing{
  letter-spacing: 1px;
}

.font-lg{
  font-size: 50px;
}

.text-white{
  color: $light;
}

.text-black{
  color: $black;
}

a{
  &:hover{
    text-decoration: none;
  }
}