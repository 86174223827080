// Fonts 
@import url('https://fonts.googleapis.com/css?family=poppins:400,600,700&display=swap|Lato:400');
body {
  line-height: 1.7;
  font-family: $secondary-font;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  color: $base-color;
  font-weight: 400;

}
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
  font-family: $primary-font; 
  color: $black;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing:-.5px;
}

h1,.h1 {
  font-size:40px;
  font-weight:800;
}
h2,.h2 {
  font-size: 32px;
}
h3,.h3 {
  font-size: 24px;
}
h4,.h4 {
  font-size:20px;
}

h5,.h5 {
  font-size:18px;
}
h6,.h6 {
  font-size:16px;
}


h1 a,h2 a,h3 a,h4 a,h5 a,h6 a{
  color: $black;

  &:hover{
    color: $primary-color;
  }
}


a{
  -webkit-transition: all .45s ease|linear;
     -moz-transition: all .45s ease|linear;
      -ms-transition: all .45s ease|linear;
       -o-transition: all .45s ease|linear;
          transition: all .45s ease|linear;
}
p {
  color:#7B7B7B;
}